<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalRd"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
        >
          <v-tooltip
            v-if="theresNotification(tab)"
            color="orange"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="orange"
                size="20"
                class="pb-7"
                style="position: absolute; z-index: 1; margin-left: -45px;"
                v-on="on"
              >
                {{ icons.mdiExclamationThick }}
              </v-icon>
            </template>
            <span>Importante</span>
          </v-tooltip>
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ApplicantInfo
            :applicant-name="applicantName"
            :applicant-sector="applicantSector"
            :applicant-store="applicantStore"
          />
          <Separator
            label="Justificativa"
            class="mt-8"
          />

          <div class="line">
            <v-text-field
              v-model="data.contract_types"
              class=""
              label="Tipo de Contrato"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.request_reason"
              class=""
              label="Motivo da Requisição"
              outlined
              dense
              disabled
            />
          </div>

          <v-text-field
            v-if="data.request_reason === 'SUBSTITUIÇÃO'"
            v-model="substituteUserName"
            class=""
            label="Funcionário Substituído"
            outlined
            dense
            disabled
          />

          <v-textarea
            v-model="data.request_reason_description"
            label="Descrição do motivo"
            outlined
            disabled
          />
          <Separator
            label="Dados da vaga"
            class="my-6"
          />

          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />
          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div
            v-if="sectorValueid === 'ff5f9668-d5b7-42f5-ae2b-5fe34854a2a0' || sectorValueid === 'd911e3ed-a989-479e-a8e9-36debec9df70' || sectorValueid === '6ce1e394-daf0-4294-827c-4d05eb4b4f91' || sectorValueid === '38b9b784-053f-4587-b8c6-346baad569dd' || sectorValueid === 'efabfa1d-da9a-482d-baa5-7af8500f5101'"
            style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 25px; height: 30px;"
          >
            <div>
              <p>A função terá a responsabilidade de vender acessórios?</p>

              <v-radio-group
                v-model="radioValue"
                row
                disabled
              >
                <v-radio
                  label="Sim"
                  value="SIM"
                  color="#9C43FA"
                />

                <v-radio
                  label="Não"
                  value="NÃO"
                  color="#9C43FA"
                />
              </v-radio-group>
            </div>
          </div>

          <div class="line mt-0">
            <v-text-field
              v-model="data.age"
              class=""
              label="Idade"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.sex"
              class=""
              label="Sexo"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.education_level"
              class=""
              label="Nível de escolaridade"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.vacancy_requirements"
            label="Requisitos da Vaga"
            outlined
            disabled
          />

          <v-textarea
            v-model="data.main_activities"
            label="Principais Atividades"
            outlined
            disabled
          />
          <FieldsetApprovalTL :data="data" />
        </v-tab-item>
        <v-tab-item>
          <Separator
            label="Dados da Função"
            class="my-8"
          />
          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div class="line">
            <v-text-field
              v-model="data.salary_value"
              class=""
              label="Salário"
              outlined
              dense
              prefix="$"
              disabled
            />
            <v-text-field
              v-model="data.class"
              class=""
              label="Classe"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.level_value"
              class=""
              label="Nível"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.atr_number"
              class=""
              label="ATR"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.human_resource_observation"
            label="Observações"
            outlined
            disabled
          />

          <div
            class="validations"
          >
            <span>Validações</span>

            <div
              v-if="data.manager_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="45px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-rd.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <p>RD</p>

                <div class="infos-text">
                  <small>{{ dateFormat(data.manager_approved_at) }}</small>

                  <div class="tag">
                    {{ data.status === 'AGUARDANDO ENCERRAMENTO' && !data.manager_approved_at ? 'Reprovada' : 'Aprovada' }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="data.director_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="45px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-director.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <p>Diretor</p>

                <div class="infos-text">
                  <small>{{ dateFormat(data.director_approved_at) }}</small>

                  <div class="tag">
                    {{ data.status === 'AGUARDANDO ENCERRAMENTO' && !data.director_approved_at ? 'Reprovada' : 'Aprovada' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Separator
            label="Informações do aprovado"
            class="my-6"
          />

          <ResultsWrapper
            :key="ResultsWrapperKey"
            :profile="profile"
            :theres-notifications="returnedToRH"
            :data="data"
            @updatedTable="handleUpdatedTable"
            @AtualizaAi="handleUpdatedTable"
          />

          <div
            class="actions-finish mt-5"
          >
            <v-btn
              v-if="!data.was_person_reproved && profileId === data.creator.id"
              color="success"
              class="ml-4"
              @click="handleOpenModalSendDP"
            >
              <div
                v-if="!loadingFinish"
                class="d-flex align-center"
                style="gap: 5px;"
              >
                <span>INSERIR PARECER</span>
                <v-icon
                  medium
                >
                  {{ icons.mdiShare }}
                </v-icon>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Separator
            label="Informações do processo"
            class="mt-6"
          />

          <div
            v-if="loadingHistory"
            class="progress-container-loading"
          >
            <v-progress-circular
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </div>

          <div
            v-else
            class="progress-container"
          >
            <span>Status do Processo</span>

            <div class="container-icon">
              <img
                src="../../../../../assets/rightIcon.svg"
                width="20"
              >

              <small class="status-concluido">CONCLUÍDO</small>
            </div>

            <div class="item-time-line-first">
              <p>REQUISIÇÃO REALIZADA</p>
              <p>{{ dateFormat(data.created_at) }}</p>
            </div>

            <div
              v-for="(item, index) in dataHistory"
              :key="item.id"
              class="container-item"
            >
              <div class="container-icon">
                <img
                  v-if="index !== lastNonCompletedIndex || hasConcluded"
                  src="../../../../../assets/rightIcon.svg"
                  width="20"
                >
                <img
                  v-else
                  src="../../../../../assets/waitingIcon.svg"
                  width="17"
                >

                <small :class="getStatusClass(index)">{{ hasConcluded ? 'CONCLUÍDO' : (index === lastNonCompletedIndex ? 'STATUS ATUAL' : 'CONCLUÍDO') }}</small>
              </div>

              <div :class="['item-time-line', {'no-border': index === dataHistory.length - 1}]">
                <p>{{ item.new_status }} </p>
                <p>{{ dateFormat(item.updated_at) }}</p>
              </div>
            </div>
          </div>

          <!-- <div>
            <Separator
              label="Informações do aprovado"
              class="mt-6"
            />

            <div class="inputs-approved">
              <v-text-field
                v-model="data.hired_person_name"
                class=""
                label="Nome"
                outlined
                dense
                disabled
              />

              <div class="wrapper-inputs">
                <v-text-field
                  v-model="data.hired_person_cpf"
                  class=""
                  label="CPF"
                  outlined
                  dense
                  disabled
                />
                <v-text-field
                  v-model="data.expected_start_date"
                  class=""
                  label="Previsão de admissão"
                  outlined
                  dense
                  disabled
                />
              </div>
            </div>

            <div
              class="chips-container-content"
              :style="{'justify-content': isLoadingGetFile ? 'center': 'flex-start'}"
            >
              <v-progress-circular
                v-show="isLoadingGetFile"
                color="warning"
                indeterminate
              />

              <v-chip
                v-for="file in files"
                v-show="computedViewFileContainer"
                :key="file.id"
                size="small"
                color="warning"
                class="chips-content"
                outlined
                dense
                @click="downloadFile(file.id, file.name)"
              >
                <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>

                <v-progress-circular
                  v-else
                  :key="file.id"
                  :size="25"
                  color="white"
                  indeterminate
                />
              </v-chip>

              <h4
                v-show="!computedViewFileContainer && !isLoadingGetFile"
                class="font-100"
              >
                - Nenhum anexo
              </h4>
            </div>

            <v-textarea
              v-model="data.rh_observations"
              class="mt-6"
              label="Observações"
              outlined
              disabled
            />
          </div> -->
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      v-model="showModalDP"
      width="540px"
    >
      <ModalSendDpConfirm
        :data="dataCancel"
        @close="showModalDP = false"
        @closeRD="handleCloseModalRd"
        @updatedTable="handleUpdatedTable"
      ></ModalSendDpConfirm>
    </v-dialog>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiCalendarBlankOutline,
  mdiCheckCircleOutline,
  mdiClose,
  mdiExclamationThick,
  mdiShare,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiTimelineCheckOutline,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalSendDpConfirm from './ModalSendDpConfirm.vue'
import ApplicantInfo from './components/ApplicantInfo.vue'
import FieldsetApprovalTL from './components/FieldsetApprovalTL.vue'
import ResultsWrapper from './components/ResultsWrapper.vue'

export default {
  components: {
    ModalSendDpConfirm,
    Separator,
    ApplicantInfo,
    FieldsetApprovalTL,
    ResultsWrapper,
  },
  mixins: [formatters],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    closeModalrd: { type: Function, default: () => {} },
    profile: { type: String, default: '' },
  },

  data() {
    return {
      ResultsWrapperKey: null,
      profileId: '',
      isLoadingFile: '',
      files: [],
      isLoadingGetFile: false,
      isViewingFile: false,
      loadingStadBy: false,
      dataCancel: {},
      showModalSendDP: false,
      hasConcluded: false,
      dataHistory: [],
      loadingFinish: false,
      showModalRH: false,
      currentDate: '',
      sectorValueid: '',
      radioValue: '',
      imageProfile: '',
      showModalDP: false,
      toggleSwitch: false,
      loadingCancel: false,
      loadingSendRD: false,
      tab: '',
      tabs: [
        { title: 'Requisição', icon: mdiAccountOutline },
        { title: 'Validação', icon: mdiAlertCircleOutline },
        { title: 'Resultados', icon: mdiCheckCircleOutline },
        { title: 'Timeline', icon: mdiTimelineCheckOutline },
      ],
      disabledInputEmployee: false,
      loadingSubmitRequestSendDirector: false,
      theresNotifications: false,
      returnedToRH: false,
      listEmployees: [],
      listFunctions: [],
      listSectors: [],
      listGenders: ['MASCULINO', 'FEMININO', 'NÃO BINÁRIO'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],
      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      reasonDescriptionValue: '',
      functionValue: '',
      sectorValue: '',
      ageValue: '',
      user: {},
      educationLevelValue: '',
      genderValue: '',
      applicantName: '',
      applicantSector: '',
      applicantStore: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',
      icons: {
        mdiShare,
        mdiClose,
        mdiAccountOutline,
        mdiThumbDownOutline,
        mdiThumbUpOutline,
        mdiCalendarBlankOutline,
        mdiCheckCircleOutline,
        mdiExclamationThick,
      },
      listTypeContract: [
        'CONTRATO EFETIVO',
        'CONTRATO TEMPORÁRIO',
        'CONTRATO POR TEMPO DETERMINADO',
        'ESTAGIÁRIO',
        'APRENDIZ',
      ],
      listReasonRequest: [
        'AUMENTO DE QUADRO',
        'PROMOÇÃO',
        'SUBSTITUIÇÃO',
      ],
      status: 'EM ANDAMENTO',
      validStatuses: ['EM ANDAMENTO', 'AGUARDANDO DP', 'AGUARDANDO ENCERRAMENTO', 'CONCLUÍDO', 'STAND BY', 'CANCELADO'],
      loadingHistory: false,
    }
  },

  computed: {

    computedViewFileContainer() {
      const isFileLoading = !this.isLoadingGetFile
      const isFileViewing = this.isViewingFile

      return isFileLoading && isFileViewing
    },
    substituteUserName() {
      return this.data.substitute_user ? this.data.substitute_user.name : ''
    },

    isStatusVisible() {
      return this.validStatuses.includes(this.data.status)
    },

    lastNonCompletedIndex() {
      if (this.hasConcluded) {
        return -1
      }

      // eslint-disable-next-line no-plusplus
      for (let i = this.dataHistory.length - 1; i >= 0; i--) {
        if (this.dataHistory[i].new_status !== 'CONCLUÍDO') {
          return i
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hasConcluded = true // Define como true se encontrar um concluído
      }

      return -1 // Retorna -1 se todos estiverem concluídos
    },
  },

  watch: {
    async toggleSwitch() {
      this.loadingStadBy = true

      // this.closeModal()

      const body = {
        status: 'STAND-BY',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        // this.$swal({
        //   icon: 'success',
        //   title: 'Sucesso!',
        //   text: 'Requisição conluída',
        //   showConfirmButton: false,
        //   timer: 3000,
        // })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao mudar status!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.updatedTable()
        this.closeModal()
        this.loadingStadBy = false
        this.handleUpdatedTable()
      }
    },
  },

  async created() {
    const userProfiles = localStorageSlim.get('userProfile', { decrypt: true })

    this.handleChangeResultsWrapperKey()

    this.applicantName = this.data.creator.name
    this.applicantStore = this.data.creator.company.city
    this.applicantSector = this.data.creator.occupation_sector.sector.name

    this.sectorValueid = this.data.sector_id
    this.radioValue = this.data.is_for_acessories ? 'SIM' : 'NÃO'
    this.getApprovedData()

    this.profileId = userProfiles.id

    this.files = this.data.attachments_shutdowns_rh

    if (this.files?.length > 0) {
      this.isViewingFile = true
    }

    this.dataCancel = this.data

    this.data.expected_start_date = this.dateFormat(this.data.expected_start_date)

    this.data.hired_date = this.dateFormat(this.data.hired_date)

    this.data.hired_person_cpf = this.formattedCpfNumber(this.data.hired_person_cpf)

    this.data.expected_start_date = !this.data.was_person_reproved ? this.data.expected_start_date : ''

    this.handleGetCurrentDate()

    this.handleGetStatusHistory()
    this.isBackToRH()
  },

  methods: {
    theresNotification(tab) {
      const alert = this.data.alert || {}
      const isResultsForLeader = () => tab.title === 'Resultados' && this.data.status === 'AGUARDANDO LÍDER' && this.profileId === this.data.created_by && alert.disqualification

      const notificationExists = isResultsForLeader()

      this.theresNotifications = notificationExists

      return notificationExists
    },
    isBackToRH() {
      const alert = this.data.alert || {}
      const isResultsForLeader = () => this.data.status === 'AGUARDANDO LÍDER' && this.profileId === this.data.created_by && alert.disqualification

      const returnedToRH = isResultsForLeader()

      this.returnedToRH = returnedToRH

      return returnedToRH
    },
    async downloadFile(fileId, fileName) {
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosIns
        .post('/api/v1/personnel_request/attachments_shutdowns_rh/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingFile = ''
        })
    },
    async getApprovedData() {
      await axiosIns.get(`/api/v1/personnel_request/classification_history/edit/${this.data.id}`)
    },
    handleChangeResultsWrapperKey() {
      this.ResultsWrapperKey = Math.random()
    },

    handleCloseModalRd() {
      this.closeModal()
    },

    handleUpdatedTable() {
      this.updatedTable()
    },

    handleOpenModalCancel() {
      this.showModalCancel = true
    },

    getStatusClass(index) {
      // eslint-disable-next-line no-nested-ternary
      return this.hasConcluded ? 'status-concluido' : (index === this.lastNonCompletedIndex ? 'status-atual' : 'status-concluido')
    },

    isLast(item) {
      return this.dataHistory.indexOf(item) === this.dataHistory.length - 1
    },

    async handleGetStatusHistory() {
      try {
        if (!this.data?.id) {
          return
        }

        this.loadingHistory = true

        const infos = await axiosIns.get(`api/v1/personnel_request/employee_request_status_history/edit/${this.data.id}`)

        this.dataHistory = infos.data.data
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao requisitar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingHistory = false
      }
    },

    handleGetCurrentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()

      const dataFormatada = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

      this.currentDate = dataFormatada
    },

    handleOpenModalSendDP() {
      this.showModalDP = true
    },

    async handleSendDirector() {
      this.loadingSubmitRequestSendDirector = true

      this.handleGetCurrentDate()

      const body = {
        status: this.data.status === 'AGUARDANDO DIRETORIA' ? 'EM ANDAMENTO' : 'AGUARDANDO DIRETORIA',
      }

      if (this.data.status === 'AGUARDANDO DIRETORIA') body.director_approved_at = this.currentDate
      if (this.data.status === 'AGUARDANDO RD') body.manager_approved_at = this.currentDate

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Enviado para diretoria com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSubmitRequestSendDirector = false
        this.updatedTable()
        this.closeModal()
      }
    },
    async handleCancelRequest() {
      this.loadingCancel = true

      const body = {
        status: 'CANCELADO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição recusada com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingCancel = false
        this.updatedTable()
        this.closeModal()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 25px;
}

.container-center{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.validations{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
}

.validations span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 8px;
}

.infos{
  display: flex;
}

.image{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag{
  background: #56CA001A;
  color: #56CA00;
  width: 85px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.infos-text{
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container{
  position: relative;
  width: 100%;
  padding: 25px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;
}

.progress-container-loading{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-container span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 12px;
  font-size: 12px;
}

.item-time-line{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
    margin-left: 10px;
  }
}

.no-border {
  border-left: 1px solid transparent;
}

.item-time-line-first{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;
  margin-bottom: -14px;

  p{
    font-size: 12px;
     margin-left: 10px;
  }
}

.container-item{
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & + &{
    margin-top: 0px;

  }
}

.item-time-line-waiting{
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
  }
}

.container-icon-waiting{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
    color: #FFB270;
  }
}

.container-icon{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
  }

  .status-atual {
    color: #FFB270;
  }

  .status-concluido {
    color: #39CE2F;
  }
}

.stand-by{
  display: flex;
  align-items: center;

  p{
    margin-top: 12px;
  }
}

.footer{
  display: flex;
  justify-content: center;
}

.inputs-approved{
  margin-top: 20px;
}

.wrapper-inputs{
  display: flex;
  gap: 10px;
}

.actions-finish{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.infos-aproved{
  margin-left: 10px;

  p{
    margin-bottom: 5px;
  }
}

.center-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs-just{
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
